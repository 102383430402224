export const cartReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CHECKOUT':
      if (
        action.customDate &&
        state.customDate &&
        action.customDate !== state.customDate
      ) {
        alert(
          'Dit product kan niet worden gecombineerd met een product dat al in het winkelmandje zit. Gelieve eerst de huidige bestelling af te ronden.'
        )
        return state
      }
      return {
        ...state,
        checkout: action.checkout,
        isAdding: false,
        orderOnWeekdays: action.orderOnWeekdays
          ? [
              ...new Set(
                [...state.orderOnWeekdays, ...action.orderOnWeekdays].filter(
                  day =>
                    (!state.orderOnWeekdays[0] ||
                      state.orderOnWeekdays.includes(day)) &&
                    action.orderOnWeekdays.includes(day)
                )
              )
            ]
          : state.orderOnWeekdays,
        minimumDays:
          action.minimumDays > state.minimumDays
            ? action.minimumDays
            : state.minimumDays,
        customDate: action.customDate || state.customDate,
        customSlot: action.customSlot || state.customSlot
      }
    case 'EMPTY_CART':
      return {
        checkout: action.checkout,
        isAdding: false,
        orderOnWeekdays: [],
        minimumDays: 0,
        customDate: null,
        customSlot: null
      }
    default:
      return state
  }
}
