exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-afrekenen-datum-js": () => import("./../../../src/pages/afrekenen/datum.js" /* webpackChunkName: "component---src-pages-afrekenen-datum-js" */),
  "component---src-pages-afrekenen-overzicht-js": () => import("./../../../src/pages/afrekenen/overzicht.js" /* webpackChunkName: "component---src-pages-afrekenen-overzicht-js" */),
  "component---src-pages-algemene-voorwaarden-js": () => import("./../../../src/pages/algemene-voorwaarden.js" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-js" */),
  "component---src-pages-annuleren-of-verplaatsen-js": () => import("./../../../src/pages/annuleren-of-verplaatsen.js" /* webpackChunkName: "component---src-pages-annuleren-of-verplaatsen-js" */),
  "component---src-pages-dessertenbuffet-index-js": () => import("./../../../src/pages/dessertenbuffet/index.js" /* webpackChunkName: "component---src-pages-dessertenbuffet-index-js" */),
  "component---src-pages-dessertenbuffet-offerte-deal-id-js": () => import("./../../../src/pages/dessertenbuffet/offerte/[...dealId].js" /* webpackChunkName: "component---src-pages-dessertenbuffet-offerte-deal-id-js" */),
  "component---src-pages-huwelijk-js": () => import("./../../../src/pages/huwelijk.js" /* webpackChunkName: "component---src-pages-huwelijk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-over-mij-js": () => import("./../../../src/pages/over-mij.js" /* webpackChunkName: "component---src-pages-over-mij-js" */),
  "component---src-pages-privacy-beleid-js": () => import("./../../../src/pages/privacy-beleid.js" /* webpackChunkName: "component---src-pages-privacy-beleid-js" */),
  "component---src-pages-producten-index-js": () => import("./../../../src/pages/producten/index.js" /* webpackChunkName: "component---src-pages-producten-index-js" */),
  "component---src-pages-veelgestelde-vragen-js": () => import("./../../../src/pages/veelgestelde-vragen.js" /* webpackChunkName: "component---src-pages-veelgestelde-vragen-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

