import React, { createContext, useReducer, useEffect } from 'react'
import Client from 'shopify-buy'

import { SHOPIFY_CHECKOUT_STORAGE_KEY } from '../enums'
import { cartReducer } from '../reducers/CartReducer'

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_KEY,
  domain: process.env.GATSBY_SHOPIFY_STORE_URL
})

function createNewCheckout (cart) {
  return cart.checkout.create()
}

function fetchCheckout (client, id) {
  return client.checkout.fetch(id)
}

const initialCartState = {
  client,
  loading: true,
  isAdding: false,
  orderOnWeekdays: [],
  minimumDays: 0,
  customDate: null,
  customSlot: null,
  checkout: { lineItems: [] }
}

const ShopifyContext = createContext({
  client,
  cart: initialCartState,
  setCart: () => null
})

const ShopifyContextProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, initialCartState)

  useEffect(() => {
    const initializeCheckout = async () => {
      const isBrowser = typeof window !== 'undefined'
      const existingCheckoutId = isBrowser
        ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
        : null
      if (existingCheckoutId) {
        try {
          const checkout = await fetchCheckout(client, existingCheckoutId)
          if (!checkout.completedAt) {
            if (checkout) {
              dispatch({ type: 'UPDATE_CHECKOUT', checkout })
            }
            return {
              client,
              loading: false,
              checkout,
              isAdding: false
            }
          }
        } catch {
          console.log(
            'Something went wrong and the checkout key had to be erased'
          )
          localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null)
        }
      }
      const newCheckout = await createNewCheckout(client)
      localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, newCheckout.id)
      console.log('The new checkout id stored is', newCheckout.dispatch)
      return {
        client,
        loading: false,
        newCheckout,
        isAdding: false
      }
    }

    initializeCheckout()
  }, [])

  return (
    <ShopifyContext.Provider value={{ client, cart, dispatch }}>
      {children}
    </ShopifyContext.Provider>
  )
}

export { ShopifyContext, ShopifyContextProvider }
